main {
    min-height: 70vh;
    background-color: #d9edf8;
}

.colorhome {
  background: -webkit-linear-gradient(35deg, #009EFF, #0c74eb  );
}

.colornav {
  color: #009EFF !important;
}

.colornav:hover {
  color: #00FFCE !important;
  border: 2px solid #00FFCE;
  padding: 0.5rem;
}

.colorfooter {
  background: -webkit-linear-gradient(35deg, #0c74eb, #009EFF   );
}

.colortextabout {
  color: #0c74eb;
}

.colortextabouth1 {
  color: #0c0b47;
}

.colorheaderbkg {
  background-color: #d9edf8;
}

.fontrtf {
  background-color: -webkit-linear-gradient(20deg,#0c74eb, #009EFF );
  color: rgb(12, 11, 11);
  font-weight: 200;
}

.font1 {
  font-size: 2.8rem !important;
  
}

.font2 {
  font-size: 1.5rem !important;
  color: rgb(35, 4, 85) !important;
  font-weight: bold;
}

.font3 {
  font-size: 1.2rem !important;
  color: rgb(212, 247, 138);
}

.card {
  border-radius: 5px;
  background: #ecf0f3;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.08), 0 0 6px rgba(0, 0, 0, 0.05);
  transition: 0.3s transform cubic-bezier(0.155, 1.105, 0.295, 1.12),
    0.3s box-shadow,
    0.3s -webkit-transform cubic-bezier(0.155, 1.105, 0.295, 1.12);
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
  padding: 10px;
}

.card:hover {
  transform: scale(1.05);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(0, 0, 0, 0.06);
}
.card-body {
  background-color: #fff;
  padding:1px;
}

/* -----Timeline----- */
.main-timeline {
    position: relative;
    color: var(--text-color)
  }
  
  .main-timeline::before {
    content: "";
    background-color: #707070;
    opacity: 0.3;
    height: 100%;
    width: 2px;
    transform: translateX(-50%);
    position: absolute;
    left: 50%;
    top: 0;
  }
  
  .main-timeline::after {
    content: "";
    display: block;
    clear: both;
  }
  
  .main-timeline .timeline {
    width: 50%;
    padding: 0 40px 0 0;
    margin: 0 5px 0 0;
    float: left;
    position: relative;
    z-index: 1;
  }
  
  .main-timeline .timeline::before,
  .main-timeline .timeline::after {
    content: "";
    background-color: #707070;
    opacity: 0.2;
    height: 2px;
    width: 100px;
    transform: translateY(-50%);
    position: absolute;
    right: 0;
    top: 50%;
  }
  
  .main-timeline .timeline::after {
    height: 15px;
    width: 15px;
    border-radius: 50%;
    display: none;
    right: -8px;
  }
  
  .main-timeline .timeline-content {
    box-shadow: var(--shadow);
    background-color: var(--bg1-color);
    border: var(--border);
    margin: 0 auto;
    border-radius: 20px;
    display: block;
    overflow: hidden;
    position: relative;
    padding: 10px;
  }
  
  .main-timeline .timeline-content:hover {
    text-decoration: none;
  }
  .main-timeline .timeline-content::before,
  .main-timeline .timeline-content::after {
    content: "";
    background-color: #ecf0f3;
    width: 0;
    position: absolute;
    right: -1px;
    top: 0;
    z-index: 1;
  }
  
  .main-timeline .timeline-content::after {
    transform: rotateX(180deg);
    z-index: 0;
  }
  
  .main-timeline .timeline:nth-child(even) {
    margin: 0 0 0 5px;
    padding: 0 0 0 40px;
    float: right;
  }
  
  .main-timeline .timeline:nth-child(even)::before {
    right: auto;
    left: 0;
  }
  
  .main-timeline .timeline:nth-child(even)::after {
    right: auto;
    left: -8px;
  }
  
  .main-timeline .timeline:nth-child(even) .timeline-content::before {
    right: auto;
    left: 0;
    transform: rotateY(180deg);
  }
  
  .main-timeline .timeline:nth-child(even) .timeline-content::after {
    right: auto;
    left: 0;
    transform: rotateY(-180deg) rotateX(180deg);
  }
  
  .mooc-title{
    color: black;
  }

  .content {
    position: relative;
    width: 100%;
    padding: 10px;
    margin: auto;
    overflow: hidden;
    height: 75%;
  }
  
  .content .content-overlay {
    background: rgba(0, 0, 0, 0.7);
    position: absolute;
    height: 99%;
    width: 100%;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    opacity: 0;
    -webkit-transition: all 0.4s ease-in-out 0s;
    -moz-transition: all 0.4s ease-in-out 0s;
    transition: all 0.4s ease-in-out 0s;
  }
  
  .content:hover .content-overlay {
    opacity: 0.8;
  }
  
  .content-details {
    position: absolute;
    text-align: center;
    width: 100%;
    left: 50%;
    top: 50%;
    opacity: 0;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -webkit-transition: all 0.3s ease-in-out 0s;
    -moz-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;
  }
  
  .content:hover .content-details {
    left: 50%;
    top: 50%;
    opacity: 1;
    transition: 0.3s ease-in;
  }
  .fadeIn-bottom {
    top: 80%;
  }

@media (min-width: 315px) and (max-width: 450px) {

  a.navbar-brand {
    width: 150px !important;
  }

  .titleprin {
    font-size: 1.9rem !important;
  }

}
  
@media (min-width: 450px) and (max-width: 640px) {

  a.navbar-brand {
    width: 200px !important;
  }

  .fontrtf {
    font-size: 0.1rem !important;
  }

}

